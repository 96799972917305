import imgJessica from '../../img/resp-tecnico.jpg'
import profissionais from '../../data/ProfissionaisData'
import { useEffect, useState } from 'react';


export default function Disclaimer(props) {
    const [jessica, setJessica] = useState('')
    const [whatsappLink, setWhatsappLink] = useState('');
    const mensagem = 'Olá, gostaria de falar sobre o projeto Falar Salva';

    useEffect(() => {
        
        setJessica(profissionais.filter(x => x.Url.includes('jessica-janis-rubatino')));
        setWhatsappLink(`https://api.whatsapp.com/send?phone=${jessica.Celular}&text=${encodeURIComponent(mensagem)}`);
        }, [])

        useEffect(() => {
            console.log(jessica);
        }, [jessica]); // Executa quando 'jessica' é atualizado

    return (
        <>
            {/* Telas menores */}
            <div className={`d-block d-sm-none bg-danger-subtle fw-medium p-xxl-4 fs-6 disclaimer ${props.className}`}>
                <div className="d-flex flex-column flex-md-row justify-content-center justify-content-lg-between col-xxl-10 py-4 mx-2 mx-lg-5">
                    <div className="d-flex flex-column align-items-center text-center fw-medium col-xxl-3 gap-1 lh-sm">
                        <p className="my-0 fw-bold">Responsável técnico</p>
                        <img className="img-fluid rounded-circle col-5 my-2" src={imgJessica} />
                        <p className="my-0 fw-semibold text-danger-emphasis">Jéssica Janis Rubatino Aleixo Mariosa Antunes</p>
                        <p className="my-0 fst-italic fw-semibold text-danger-emphasis">CRP 04/64738</p>
                    </div>
                    <div>
                    <p className="my-auto fw-bold py-4">Atenção: Este site não oferece tratamento ou aconselhamento imediato para pessoas em crise suicida. Em caso de crise, ligue para 188 (CVV) ou acesse o site www.cvv.org.br. Em caso de emergência, procure atendimento em um hospital mais próximo.</p>
                    <div className='ms-auto'>Para dúvidas, reclamações e sugestões, <span><a className='link-underline-primary' target='_blank' href={whatsappLink}>clique aqui</a></span></div>
                    </div>
                </div>
            </div>
            {/* Telas maiores */}
            <div className={`d-none d-sm-flex justify-content-center bg-danger-subtle fw-medium fs-5 disclaimer ${props.className}`}>
                <div className="d-flex flex-column flex-md-row justify-content-center justify-content-lg-between gap-2 col-xxl-10 py-4 mx-2 mx-md-5">
                    <div className="d-flex flex-column align-items-center  fw-medium col-md-6 col-lg-4 col-xxl-3 lh-sm">
                        <p className="my-0 fw-bold text-danger-emphasis">Responsável técnico</p>
                        <img className="img-fluid rounded-circle col-sm-4 col-md-6 col-lg-6 col-xxl-7 my-3" src={imgJessica} />
                        <p className="my-0 text-center fw-semibold text-danger-emphasis">Jéssica Janis Rubatino Aleixo Mariosa Antunes</p>
                        <p className="my-0 fst-italic fw-semibold text-danger-emphasis">CRP 04/64738</p>
                    </div>
                    <div className='my-auto col-xl-8'>
                        <div className="fs-3 fw-bold">Atenção: Este site não oferece tratamento ou aconselhamento imediato para pessoas em crise suicida. Em caso de crise, ligue para 188 (CVV) ou acesse o site www.cvv.org.br. Em caso de emergência, procure atendimento em um hospital mais próximo.</div>
                        <div className='ms-auto  mt-2'>Para dúvidas, reclamações e sugestões, <span><a className='link-underline-primary' target='_blank' href={whatsappLink}>clique aqui</a></span></div>
                    </div>
                </div>
            </div>



        </>
    )
}