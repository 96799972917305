export function criaLinkWhatsapp(celular) {
    return `https://wa.me//${celular}?text=Ol%C3%A1%2C%20estou%20vindo%20pelo%20site%20do%20projeto%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es...`
}

export function ordenarLista(a, b) {
    var mesAtual = new Date().getMonth() + 1;
    let ordenacao = [];

    switch (mesAtual) {
        case 1:
        case 5:
        case 9:
            ordenacao = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
            return ordenacao.indexOf(a.Id) - ordenacao.indexOf(b.Id);
        case 2:
        case 6:
        case 10:
            ordenacao = [7, 8, 9, 10, 1, 2, 3, 4, 5, 6];
            return ordenacao.indexOf(a.Id) - ordenacao.indexOf(b.Id);
        case 3:
        case 7:
        case 11:
            ordenacao = [3, 4, 5, 6, 7, 8, 9, 10, 1, 2];
            return ordenacao.indexOf(a.Id) - ordenacao.indexOf(b.Id);
        case 4:
        case 8:
        case 12:
            ordenacao = [9, 10, 1, 2, 3, 4, 5, 6, 7, 8];
            return ordenacao.indexOf(a.Id) - ordenacao.indexOf(b.Id);
    }
}
